const CALLS_RESET_PATH_PATTERN =
  /^\/calls\/(genesys|nice|pilot|webex)\/reset\/?$/i

const CALLS_CRASH_SCREEN_PATH =
  'https://assets.thriveglobal.com/reset/video/webm/60seconds/021a46d3-e0e5-450b-8b2e-fd91d85a29ff.webm'

// Display a predefined reset video to agents when the calls app fails to load,
// as the default crash screen is not relevant to them.
export const maybeHandleCallsError = () => {
  if (CALLS_RESET_PATH_PATTERN.test(window.location.pathname)) {
    window.location.href = CALLS_CRASH_SCREEN_PATH
  }
}
