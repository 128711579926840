import {
  AppError,
  getAppStatus,
  LOAD_ERROR,
  getAppNames,
  unregisterApplication,
} from 'single-spa'
import { LayoutEngine } from 'single-spa-layout/dist/types/browser/constructLayoutEngine'
import crashScreen from '../screens/crash-screen.html'
import { captureError } from './datadog'
import { maybeHandleCallsError } from './callsErrorHandler'

const appErrorHandler = (layoutEngine: LayoutEngine) => {
  return (err: AppError) => {
    console.error(`Error loading ${err.appOrParcelName}:`, err)
    if (getAppStatus(err.appOrParcelName) === LOAD_ERROR) {
      layoutEngine.deactivate()
      getAppNames().forEach((app) => unregisterApplication(app))

      document.body.innerHTML = crashScreen
      captureError(err, { message: `${err.appOrParcelName}: Failed to Load` })

      maybeHandleCallsError()
    }
  }
}

export default appErrorHandler
